
                                @import '@/assets/sass/vuetify/variables.scss';
                                @import '@/assets/sass/colors.scss';
                            

@import '~vuetify/src/styles/settings/_variables';

.ampifire-banner {
    .ampifire-banner--content {
        position: relative;
        z-index: 1;
    }

    .ampifire-banner-info {
        position: relative;
        @media #{map-get($display-breakpoints, 'md-and-up')} {
            background-image: url('@/assets/img/question-background.svg');
        }
        background-size: contain;
        background-position-x: right;
        background-repeat: no-repeat;
    }

    .add-letter-spacing {
        letter-spacing: 3px; /*making characters with the same occupied size.*/
    }

    .instruction-number {
        color: $secondary-color;
        background-color: $blueish;
    }
}
