
                                @import '@/assets/sass/vuetify/variables.scss';
                                @import '@/assets/sass/colors.scss';
                            

.module-tabs::v-deep {
    .module-tab {
        letter-spacing: normal;

        &:after {
            opacity: 0.1;
            bottom: 0;
            content: '';
            left: 10px;
            pointer-events: none;
            position: absolute;
            right: 10px;
            top: 0;
            transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
            border-bottom: 4px solid $secondary-color;
        }

        &:hover {
            &:after {
                opacity: 1;
                left: 0;
                right: 0;
                border-bottom: 4px solid $primary-color;
            }
        }

        &:not(.v-tab--active):hover,
        &:not(.v-tab--active):hover > .v-icon {
            color: $primary-color !important;
        }
    }
}
